
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_93_1Nxrdjrtq4gA8Tp0VfQnh3P1zU0JJpmKOqOXI_xuFEMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/booking/[id].vue?macro=true";
import { default as buffet_45styleF8rtaix8TLhnKcybRTvYNVTXQQEfQ0ZkINyRTYDMhFQMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/buffet-style.vue?macro=true";
import { default as faq7_Xm_45ftYx73_45CMdd3f_ZtkDDVzCJUaS_45RGUI3DMWESwMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/faq.vue?macro=true";
import { default as galleryqZBMfj4aEun1XvsQejPDFn1WZRPrLjPkO07ygm_450U4kMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/gallery.vue?macro=true";
import { default as _91id_93697v5MWtVAJaCrzAcPQYNIROoNsnRLbL_45Wohe5riA6oMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/hibachi-catering/[id].vue?macro=true";
import { default as indexyuu5aRwGrYq0OyXzYF505Y3scRMpZDbUR8LLUxKo4GgMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/index.vue?macro=true";
import { default as locations_45servicedG7ofnbffCTRMYd6_45bY0rpT1WUyawgUPA6W2Tfv1w_456UMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/locations-serviced.vue?macro=true";
import { default as locationsBiKe34fmIcYTLGfoqPJNpKSM4b74_7tSwmNukwBx8agMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/locations.vue?macro=true";
import { default as menuGYXsinnE__qdzlUlsdTsn_45oWl4ymm0wWiAi9Idt9lpkMeta } from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/menu.vue?macro=true";
export default [
  {
    name: "booking-id",
    path: "/booking/:id()",
    meta: _91id_93_1Nxrdjrtq4gA8Tp0VfQnh3P1zU0JJpmKOqOXI_xuFEMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/booking/[id].vue")
  },
  {
    name: "buffet-style",
    path: "/buffet-style",
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/buffet-style.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faq7_Xm_45ftYx73_45CMdd3f_ZtkDDVzCJUaS_45RGUI3DMWESwMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/faq.vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: galleryqZBMfj4aEun1XvsQejPDFn1WZRPrLjPkO07ygm_450U4kMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/gallery.vue")
  },
  {
    name: "hibachi-catering-id",
    path: "/hibachi-catering/:id()",
    meta: _91id_93697v5MWtVAJaCrzAcPQYNIROoNsnRLbL_45Wohe5riA6oMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/hibachi-catering/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexyuu5aRwGrYq0OyXzYF505Y3scRMpZDbUR8LLUxKo4GgMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/index.vue")
  },
  {
    name: "locations-serviced",
    path: "/locations-serviced",
    meta: locations_45servicedG7ofnbffCTRMYd6_45bY0rpT1WUyawgUPA6W2Tfv1w_456UMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/locations-serviced.vue")
  },
  {
    name: "locations",
    path: "/locations",
    meta: locationsBiKe34fmIcYTLGfoqPJNpKSM4b74_7tSwmNukwBx8agMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/locations.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: menuGYXsinnE__qdzlUlsdTsn_45oWl4ymm0wWiAi9Idt9lpkMeta || {},
    component: () => import("/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/pages/menu.vue")
  }
]