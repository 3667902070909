import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34 from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68 from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import unocss_6Z4vW7S9aX_q2svWbGBc_X2b5QbQdkNmvzr_3kqqCd0 from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/node_modules/.cache/nuxt/.nuxt/unocss.mjs";
import devtools_client_leo_G5iKF5pflvHu4GfHBYDKdkt9KijAelx5g26VYdE from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/plugins/devtools.client.ts";
import youtube_client_DMx4rvQMgy2LzUUcPn1zia9EodRfmQ0GrbbCiP0WeMM from "/Users/baidu/www/hibachi/hibachi-hub/hibachinow/app/plugins/youtube.client.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34,
  plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  unocss_6Z4vW7S9aX_q2svWbGBc_X2b5QbQdkNmvzr_3kqqCd0,
  devtools_client_leo_G5iKF5pflvHu4GfHBYDKdkt9KijAelx5g26VYdE,
  youtube_client_DMx4rvQMgy2LzUUcPn1zia9EodRfmQ0GrbbCiP0WeMM
]